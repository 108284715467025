<template>
  <div class="create_image">
    <div class="dia_problem" v-if="problemList.length > 0">
      <div class="flex-y-c mb10">
        <img
          class="mr10"
          style="width: 24px"
          src="../../../../../common/imgs/case/dia_warn_cion.png"
          alt=""
        />
        <h5>{{ $t("cases.createImage.czrxwt") }}</h5>
      </div>
      <div class="pro_box">
        <span
          @click="jumpLocation(item.groupNo)"
          class="curp"
          v-for="(item, index) in problemList"
          :key="`${item.id}_${index}`"
        >
          {{ item.groupNo }} {{ item.groupName }}
        </span>
      </div>
    </div>
    <div class="image_single" :id="disposeId('face')">
      <div class="image_single_header">
        <span class="vertical_line"></span>
        <p class="fz18 main_theme_color_333 mr10">
          {{ $t("cases.createImage.kqnzp") }}
        </p>
        <img
          style="width: 12px"
          class="dib mr4"
          src="../../../../../common/imgs/case/creater_img_ask.png"
          alt=""
        />
        <router-link
          target="_blank"
          style="display: flex; align-items: center"
          :to="{ name: 'helpCenter', query: { type: '12' } }"
        >
          <span class="main_theme_color fz14 curp">{{
            $t("cases.createImage.zppsbz")
          }}</span>
        </router-link>
      </div>
      <div class="single_con">
        <p class="problem_note" v-if="diagnosisProblem[repulseNumber('face')]">
          {{ diagnosisProblem[repulseNumber("face")] }}
        </p>
        <ul class="up_ul">
          <li class="up_li">
            <div class="up_load">
              <div
                class="up_load_img"
                @dragover.prevent
                @drop.prevent="
                  event => {
                    handleDrop(
                      event,
                      'sideFacePicture',
                      $route.query.productType !== 'F'
                        ? $t('cases.createImage.cmx')
                        : $t('cases.createImage.zcmwxx')
                    );
                  }
                "
                @click="
                  showCarousel({
                    filed: 'sideFacePicture',
                    text:
                      queryProductType !== 'F'
                        ? $t('cases.createImage.cmx')
                        : $t('cases.createImage.zcmwxx'),
                    index: getPicIndex('sideFacePicture')
                  })
                "
                v-show="diagnosisParams.sideFacePicture['nanoId']"
              >
                <img
                  v-if="diagnosisParams.sideFacePicture['nanoId']"
                  :src="
                    `${$PicPrefix}${diagnosisParams.sideFacePicture['nanoId']}?imageView2/0/w/360/h/250`
                  "
                  :style="{
                    transform: `rotate(${
                      diagnosisParams.sideFacePicture['degree']
                    }deg) scaleY(${
                      diagnosisParams.sideFacePicture['vertical'] ? -1 : 1
                    }) scaleX(${
                      diagnosisParams.sideFacePicture['horizontal'] ? -1 : 1
                    })`
                  }"
                  alt=""
                />
                <p class="pic_look">
                  <img src="/static/images/createCases/check_icon.png" alt="" />
                  <span>{{ $t("cases.createImage.ck") }}</span>
                </p>
              </div>
              <el-upload
                class="upload-demo"
                drag
                :data="{ token: uploadToken, key: nanoid }"
                :on-success="
                  res =>
                    uploadSuccess(
                      res,
                      'sideFacePicture',
                      queryProductType !== 'F'
                        ? $t('cases.createImage.cmx')
                        : $t('cases.createImage.zcmwxx')
                    )
                "
                :on-error="uploadError"
                :before-upload="beforeUpload"
                accept="image/jpeg,image/bmp,image/png"
                :action="UPLOAD_URL"
                :show-file-list="false"
                list-type="picture"
                v-show="!diagnosisParams.sideFacePicture['nanoId']"
              >
                <div class="up_box">
                  <p class="up_box_tip">
                    <span class="up___tip_icon"></span
                    >{{ $t("cases.createImage.sctz") }}
                  </p>
                </div>
                <p
                  class="up_load_def up_load_def1"
                  v-show="!diagnosisParams.sideFacePicture['nanoId']"
                ></p>
              </el-upload>
              <span
                class="remove_pic"
                v-show="diagnosisParams.sideFacePicture['nanoId']"
                @click="() => removeFiledPic('sideFacePicture')"
              ></span>
            </div>
            <p class="up_txt">
              <span
                v-if="queryProductType !== 'D'"
                class="main_theme_color_red mr4"
                >●</span
              >{{
                queryProductType !== "F"
                  ? $t("cases.createImage.cmx")
                  : $t("cases.createImage.zcmwxx")
              }}
            </p>
          </li>
          <li class="up_li">
            <div class="up_load">
              <div
                @dragover.prevent
                @drop.prevent="
                  event => {
                    handleDrop(
                      event,
                      'frontFacePicture',
                      queryProductType !== 'F'
                        ? $t('cases.createImage.zmx')
                        : $t('cases.createImage.zmwxx')
                    );
                  }
                "
                class="up_load_img"
                @click="
                  showCarousel({
                    filed: 'frontFacePicture',
                    text:
                      queryProductType !== 'F'
                        ? $t('cases.createImage.zmx')
                        : $t('cases.createImage.zmwxx'),
                    index: getPicIndex('frontFacePicture')
                  })
                "
                v-show="diagnosisParams.frontFacePicture['nanoId']"
              >
                <img
                  v-if="diagnosisParams.frontFacePicture['nanoId']"
                  :src="
                    `${$PicPrefix}${diagnosisParams.frontFacePicture['nanoId']}?imageView2/0/w/360/h/250`
                  "
                  :style="{
                    transform: `rotate(${
                      diagnosisParams.frontFacePicture['degree']
                    }deg) scaleY(${
                      diagnosisParams.frontFacePicture['vertical'] ? -1 : 1
                    }) scaleX(${
                      diagnosisParams.frontFacePicture['horizontal'] ? -1 : 1
                    })`
                  }"
                  alt=""
                />
                <p class="pic_look">
                  <img src="/static/images/createCases/check_icon.png" alt="" />
                  <span>{{ $t("cases.createImage.ck") }}</span>
                </p>
              </div>
              <el-upload
                class="upload-demo"
                drag
                :data="{ token: uploadToken, key: nanoid }"
                :on-success="
                  res =>
                    uploadSuccess(
                      res,
                      'frontFacePicture',
                      queryProductType !== 'F'
                        ? $t('cases.createImage.zmx')
                        : $t('cases.createImage.zmwxx')
                    )
                "
                :on-error="uploadError"
                :before-upload="beforeUpload"
                accept="image/jpeg,image/bmp,image/png"
                :action="UPLOAD_URL"
                :show-file-list="false"
                list-type="picture"
                v-show="!diagnosisParams.frontFacePicture['nanoId']"
              >
                <div class="up_box">
                  <p class="up_box_tip">
                    <span class="up___tip_icon"></span
                    >{{ $t("cases.createImage.sctz") }}
                  </p>
                </div>
                <p
                  class="up_load_def up_load_def2"
                  v-show="!diagnosisParams.frontFacePicture['nanoId']"
                ></p>
              </el-upload>
              <span
                class="remove_pic"
                v-show="diagnosisParams.frontFacePicture['nanoId']"
                @click="() => removeFiledPic('frontFacePicture')"
              ></span>
            </div>
            <p class="up_txt">
              <span
                v-if="queryProductType !== 'D'"
                class="main_theme_color_red mr4"
                >●</span
              >{{
                queryProductType !== "F"
                  ? $t("cases.createImage.zmx")
                  : $t("cases.createImage.zmwxx")
              }}
            </p>
          </li>
          <li class="up_li">
            <div class="up_load">
              <div
                class="up_load_img"
                @dragover.prevent
                @drop.prevent="
                  event => {
                    handleDrop(
                      event,
                      'smilePicture',
                      queryProductType !== 'F'
                        ? $t('cases.createImage.wxx')
                        : $t('cases.createImage.ycmwxx')
                    );
                  }
                "
                @click="
                  showCarousel({
                    filed: 'smilePicture',
                    text:
                      queryProductType !== 'F'
                        ? $t('cases.createImage.wxx')
                        : $t('cases.createImage.ycmwxx'),
                    index: getPicIndex('smilePicture')
                  })
                "
                v-show="diagnosisParams.smilePicture['nanoId']"
              >
                <img
                  v-if="diagnosisParams.smilePicture['nanoId']"
                  :src="
                    `${$PicPrefix}${diagnosisParams.smilePicture['nanoId']}?imageView2/0/w/360/h/250`
                  "
                  :style="{
                    transform: `rotate(${
                      diagnosisParams.smilePicture['degree']
                    }deg) scaleY(${
                      diagnosisParams.smilePicture['vertical'] ? -1 : 1
                    }) scaleX(${
                      diagnosisParams.smilePicture['horizontal'] ? -1 : 1
                    })`
                  }"
                  alt=""
                />
                <p class="pic_look">
                  <img src="/static/images/createCases/check_icon.png" alt="" />
                  <span>{{ $t("cases.createImage.ck") }}</span>
                </p>
              </div>
              <el-upload
                class="upload-demo"
                drag
                :data="{ token: uploadToken, key: nanoid }"
                :on-success="
                  res =>
                    uploadSuccess(
                      res,
                      'smilePicture',
                      queryProductType !== 'F'
                        ? $t('cases.createImage.wxx')
                        : $t('cases.createImage.ycmwxx')
                    )
                "
                :on-error="uploadError"
                :before-upload="beforeUpload"
                accept="image/jpeg,image/bmp,image/png"
                :action="UPLOAD_URL"
                :show-file-list="false"
                list-type="picture"
                v-show="!diagnosisParams.smilePicture['nanoId']"
              >
                <div class="up_box">
                  <p class="up_box_tip">
                    <span class="up___tip_icon"></span
                    >{{ $t("cases.createImage.sctz") }}
                  </p>
                </div>
                <p
                  class="up_load_def up_load_def3"
                  v-show="!diagnosisParams.smilePicture['nanoId']"
                ></p>
              </el-upload>
              <span
                class="remove_pic"
                v-show="diagnosisParams.smilePicture['nanoId']"
                @click="() => removeFiledPic('smilePicture')"
              ></span>
            </div>
            <p class="up_txt">
              <span
                v-if="queryProductType !== 'D'"
                class="main_theme_color_red mr4"
                >●</span
              >{{
                queryProductType !== "F"
                  ? $t("cases.createImage.wxx")
                  : $t("cases.createImage.ycmwxx")
              }}
            </p>
          </li>
          <template v-if="queryProductType !== 'F'">
            <li class="up_li">
              <div class="up_load">
                <div
                  class="up_load_img"
                  @dragover.prevent
                  @drop.prevent="
                    event => {
                      handleDrop(
                        event,
                        'upToothPicture',
                        $t('cases.createImage.sylx')
                      );
                    }
                  "
                  @click="
                    showCarousel({
                      filed: 'upToothPicture',
                      text: $t('cases.createImage.sylx'),
                      index: getPicIndex('upToothPicture')
                    })
                  "
                  v-show="diagnosisParams.upToothPicture['nanoId']"
                >
                  <img
                    v-if="diagnosisParams.upToothPicture['nanoId']"
                    :src="
                      `${$PicPrefix}${diagnosisParams.upToothPicture['nanoId']}?imageView2/0/w/360/h/250`
                    "
                    :style="{
                      transform: `rotate(${
                        diagnosisParams.upToothPicture['degree']
                      }deg) scaleY(${
                        diagnosisParams.upToothPicture['vertical'] ? -1 : 1
                      }) scaleX(${
                        diagnosisParams.upToothPicture['horizontal'] ? -1 : 1
                      })`
                    }"
                    alt=""
                  />
                  <p class="pic_look">
                    <img
                      src="/static/images/createCases/check_icon.png"
                      alt=""
                    />
                    <span>{{ $t("cases.createImage.ck") }}</span>
                  </p>
                </div>
                <el-upload
                  class="upload-demo"
                  drag
                  :data="{ token: uploadToken, key: nanoid }"
                  :on-success="
                    res =>
                      uploadSuccess(
                        res,
                        'upToothPicture',
                        $t('cases.createImage.sylx')
                      )
                  "
                  :on-error="uploadError"
                  :before-upload="beforeUpload"
                  accept="image/jpeg,image/bmp,image/png"
                  :action="UPLOAD_URL"
                  :show-file-list="false"
                  list-type="picture"
                  v-show="!diagnosisParams.upToothPicture['nanoId']"
                >
                  <div class="up_box">
                    <p class="up_box_tip">
                      <span class="up___tip_icon"></span
                      >{{ $t("cases.createImage.sctz") }}
                    </p>
                  </div>
                  <p
                    class="up_load_def up_load_def4"
                    v-show="!diagnosisParams.upToothPicture['nanoId']"
                  ></p>
                </el-upload>
                <span
                  class="remove_pic"
                  v-show="diagnosisParams.upToothPicture['nanoId']"
                  @click="() => removeFiledPic('upToothPicture')"
                ></span>
              </div>
              <p class="up_txt">
                <span
                  v-if="queryProductType !== 'D'"
                  class="main_theme_color_red mr4"
                  >●</span
                >{{ $t("cases.createImage.sylx") }}
              </p>
            </li>
            <li class="up_li">
              <div class="up_load">
                <div
                  class="up_load_img"
                  @dragover.prevent
                  @drop.prevent="
                    event => {
                      handleDrop(
                        event,
                        'anteriorOverjetPicture',
                        $t('cases.createImage.qyfgx')
                      );
                    }
                  "
                  @click="
                    showCarousel({
                      filed: 'anteriorOverjetPicture',
                      text: $t('cases.createImage.qyfgx'),
                      index: getPicIndex('anteriorOverjetPicture')
                    })
                  "
                  v-show="diagnosisParams.anteriorOverjetPicture['nanoId']"
                >
                  <img
                    v-if="diagnosisParams.anteriorOverjetPicture['nanoId']"
                    :src="
                      `${$PicPrefix}${diagnosisParams.anteriorOverjetPicture['nanoId']}?imageView2/0/w/360/h/250`
                    "
                    :style="{
                      transform: `rotate(${
                        diagnosisParams.anteriorOverjetPicture['degree']
                      }deg) scaleY(${
                        diagnosisParams.anteriorOverjetPicture['vertical']
                          ? -1
                          : 1
                      }) scaleX(${
                        diagnosisParams.anteriorOverjetPicture['horizontal']
                          ? -1
                          : 1
                      })`
                    }"
                    alt=""
                  />
                  <p class="pic_look">
                    <img
                      src="/static/images/createCases/check_icon.png"
                      alt=""
                    />
                    <span>{{ $t("cases.createImage.ck") }}</span>
                  </p>
                </div>
                <el-upload
                  class="upload-demo"
                  drag
                  :data="{ token: uploadToken, key: nanoid }"
                  :on-success="
                    res =>
                      uploadSuccess(
                        res,
                        'anteriorOverjetPicture',
                        $t('cases.createImage.qyfgx')
                      )
                  "
                  :on-error="uploadError"
                  :before-upload="beforeUpload"
                  accept="image/jpeg,image/bmp,image/png"
                  :action="UPLOAD_URL"
                  :show-file-list="false"
                  list-type="picture"
                  v-show="!diagnosisParams.anteriorOverjetPicture['nanoId']"
                >
                  <div class="up_box">
                    <p class="up_box_tip">
                      <span class="up___tip_icon"></span
                      >{{ $t("cases.createImage.sctz") }}
                    </p>
                  </div>
                  <p
                    class="up_load_def up_load_def10"
                    v-show="!diagnosisParams.anteriorOverjetPicture['nanoId']"
                  ></p>
                </el-upload>
                <span
                  class="remove_pic"
                  v-show="diagnosisParams.anteriorOverjetPicture['nanoId']"
                  @click="() => removeFiledPic('anteriorOverjetPicture')"
                ></span>
              </div>
              <p class="up_txt">
                <span
                  v-if="queryProductType !== 'D'"
                  class="main_theme_color_red mr4"
                  >●</span
                >{{ $t("cases.createImage.qyfgx") }}
              </p>
            </li>
            <li class="up_li">
              <div class="up_load">
                <div
                  class="up_load_img"
                  @dragover.prevent
                  @drop.prevent="
                    event => {
                      handleDrop(
                        event,
                        'downToothPicture',
                        $t('cases.createImage.xylx')
                      );
                    }
                  "
                  @click="
                    showCarousel({
                      filed: 'downToothPicture',
                      text: $t('cases.createImage.xylx'),
                      index: getPicIndex('downToothPicture')
                    })
                  "
                  v-show="diagnosisParams.downToothPicture['nanoId']"
                >
                  <img
                    v-if="diagnosisParams.downToothPicture['nanoId']"
                    :src="
                      `${$PicPrefix}${diagnosisParams.downToothPicture['nanoId']}?imageView2/0/w/360/h/250`
                    "
                    :style="{
                      transform: `rotate(${
                        diagnosisParams.downToothPicture['degree']
                      }deg) scaleY(${
                        diagnosisParams.downToothPicture['vertical'] ? -1 : 1
                      }) scaleX(${
                        diagnosisParams.downToothPicture['horizontal'] ? -1 : 1
                      })`
                    }"
                    alt=""
                  />
                  <p class="pic_look">
                    <img
                      src="/static/images/createCases/check_icon.png"
                      alt=""
                    />
                    <span>{{ $t("cases.createImage.ck") }}</span>
                  </p>
                </div>
                <el-upload
                  class="upload-demo"
                  drag
                  :data="{ token: uploadToken, key: nanoid }"
                  :on-success="
                    res =>
                      uploadSuccess(
                        res,
                        'downToothPicture',
                        $t('cases.createImage.xylx')
                      )
                  "
                  :on-error="uploadError"
                  :before-upload="beforeUpload"
                  accept="image/jpeg,image/bmp,image/png"
                  :action="UPLOAD_URL"
                  :show-file-list="false"
                  list-type="picture"
                  v-show="!diagnosisParams.downToothPicture['nanoId']"
                >
                  <div class="up_box">
                    <p class="up_box_tip">
                      <span class="up___tip_icon"></span
                      >{{ $t("cases.createImage.sctz") }}
                    </p>
                  </div>
                  <p
                    class="up_load_def up_load_def5"
                    v-show="!diagnosisParams.downToothPicture['nanoId']"
                  ></p>
                </el-upload>
                <span
                  class="remove_pic"
                  v-show="diagnosisParams.downToothPicture['nanoId']"
                  @click="() => removeFiledPic('downToothPicture')"
                ></span>
              </div>
              <p class="up_txt">
                <span
                  v-if="queryProductType !== 'D'"
                  class="main_theme_color_red mr4"
                  >●</span
                >{{ $t("cases.createImage.xylx") }}
              </p>
            </li>
            <li class="up_li">
              <div class="up_load">
                <div
                  class="up_load_img"
                  @dragover.prevent
                  @drop.prevent="
                    event => {
                      handleDrop(
                        event,
                        'mouthRightPicture',
                        $t('cases.createImage.knzcyx')
                      );
                    }
                  "
                  @click="
                    showCarousel({
                      filed: 'mouthRightPicture',
                      text: $t('cases.createImage.knzcyx'),
                      index: getPicIndex('mouthRightPicture')
                    })
                  "
                  v-show="diagnosisParams.mouthRightPicture['nanoId']"
                >
                  <img
                    v-if="diagnosisParams.mouthRightPicture['nanoId']"
                    :src="
                      `${$PicPrefix}${diagnosisParams.mouthRightPicture['nanoId']}?imageView2/0/w/360/h/250`
                    "
                    :style="{
                      transform: `rotate(${
                        diagnosisParams.mouthRightPicture['degree']
                      }deg) scaleY(${
                        diagnosisParams.mouthRightPicture['vertical'] ? -1 : 1
                      }) scaleX(${
                        diagnosisParams.mouthRightPicture['horizontal'] ? -1 : 1
                      })`
                    }"
                    alt=""
                  />
                  <p class="pic_look">
                    <img
                      src="/static/images/createCases/check_icon.png"
                      alt=""
                    />
                    <span>{{ $t("cases.createImage.ck") }}</span>
                  </p>
                </div>
                <el-upload
                  class="upload-demo"
                  drag
                  :data="{ token: uploadToken, key: nanoid }"
                  :on-success="
                    res =>
                      uploadSuccess(
                        res,
                        'mouthRightPicture',
                        $t('cases.createImage.knzcyx')
                      )
                  "
                  :on-error="uploadError"
                  :before-upload="beforeUpload"
                  accept="image/jpeg,image/bmp,image/png"
                  :action="UPLOAD_URL"
                  :show-file-list="false"
                  list-type="picture"
                  v-show="!diagnosisParams.mouthRightPicture['nanoId']"
                >
                  <div class="up_box">
                    <p class="up_box_tip">
                      <span class="up___tip_icon"></span
                      >{{ $t("cases.createImage.sctz") }}
                    </p>
                  </div>
                  <p
                    class="up_load_def up_load_def6"
                    v-show="!diagnosisParams.mouthRightPicture['nanoId']"
                  ></p>
                </el-upload>
                <span
                  class="remove_pic"
                  v-show="diagnosisParams.mouthRightPicture['nanoId']"
                  @click="() => removeFiledPic('mouthRightPicture')"
                ></span>
              </div>
              <p class="up_txt">
                <span
                  v-if="queryProductType !== 'D'"
                  class="main_theme_color_red mr4"
                  >●</span
                >{{ $t("cases.createImage.knzcyx") }}
              </p>
            </li>
            <li class="up_li">
              <div class="up_load">
                <div
                  class="up_load_img"
                  @dragover.prevent
                  @drop.prevent="
                    event => {
                      handleDrop(
                        event,
                        'mouthFrontPicture',
                        $t('cases.createImage.knzwx')
                      );
                    }
                  "
                  @click="
                    showCarousel({
                      filed: 'mouthFrontPicture',
                      text: $t('cases.createImage.knzwx'),
                      index: getPicIndex('mouthFrontPicture')
                    })
                  "
                  v-show="diagnosisParams.mouthFrontPicture['nanoId']"
                >
                  <img
                    v-if="diagnosisParams.mouthFrontPicture['nanoId']"
                    :src="
                      `${$PicPrefix}${diagnosisParams.mouthFrontPicture['nanoId']}?imageView2/0/w/360/h/250`
                    "
                    :style="{
                      transform: `rotate(${
                        diagnosisParams.mouthFrontPicture['degree']
                      }deg) scaleY(${
                        diagnosisParams.mouthFrontPicture['vertical'] ? -1 : 1
                      }) scaleX(${
                        diagnosisParams.mouthFrontPicture['horizontal'] ? -1 : 1
                      })`
                    }"
                    alt=""
                  />
                  <p class="pic_look">
                    <img
                      src="/static/images/createCases/check_icon.png"
                      alt=""
                    />
                    <span>{{ $t("cases.createImage.ck") }}</span>
                  </p>
                </div>
                <el-upload
                  class="upload-demo"
                  drag
                  :data="{ token: uploadToken, key: nanoid }"
                  :on-success="
                    res =>
                      uploadSuccess(
                        res,
                        'mouthFrontPicture',
                        $t('cases.createImage.knzwx')
                      )
                  "
                  :on-error="uploadError"
                  :before-upload="beforeUpload"
                  accept="image/jpeg,image/bmp,image/png"
                  :action="UPLOAD_URL"
                  :show-file-list="false"
                  list-type="picture"
                  v-show="!diagnosisParams.mouthFrontPicture['nanoId']"
                >
                  <div class="up_box">
                    <p class="up_box_tip">
                      <span class="up___tip_icon"></span
                      >{{ $t("cases.createImage.sctz") }}
                    </p>
                  </div>
                  <p
                    class="up_load_def up_load_def7"
                    v-show="!diagnosisParams.mouthFrontPicture['nanoId']"
                  ></p>
                </el-upload>
                <span
                  class="remove_pic"
                  v-show="diagnosisParams.mouthFrontPicture['nanoId']"
                  @click="() => removeFiledPic('mouthFrontPicture')"
                ></span>
              </div>
              <p class="up_txt">
                <span class="main_theme_color_red mr4">●</span
                >{{ $t("cases.createImage.knzwx") }}
              </p>
            </li>
            <li class="up_li">
              <div class="up_load">
                <div
                  class="up_load_img"
                  @dragover.prevent
                  @drop.prevent="
                    event => {
                      handleDrop(
                        event,
                        'mouthLeftPicture',
                        $t('cases.createImage.knycyx')
                      );
                    }
                  "
                  v-show="diagnosisParams.mouthLeftPicture['nanoId']"
                  @click="
                    showCarousel({
                      filed: 'mouthLeftPicture',
                      text: $t('cases.createImage.knycyx'),
                      index: getPicIndex('mouthLeftPicture')
                    })
                  "
                >
                  <img
                    v-if="diagnosisParams.mouthLeftPicture['nanoId']"
                    :src="
                      `${$PicPrefix}${diagnosisParams.mouthLeftPicture['nanoId']}?imageView2/0/w/360/h/250`
                    "
                    :style="{
                      transform: `rotate(${
                        diagnosisParams.mouthLeftPicture['degree']
                      }deg) scaleY(${
                        diagnosisParams.mouthLeftPicture['vertical'] ? -1 : 1
                      }) scaleX(${
                        diagnosisParams.mouthLeftPicture['horizontal'] ? -1 : 1
                      })`
                    }"
                    alt=""
                  />
                  <p class="pic_look">
                    <img
                      src="/static/images/createCases/check_icon.png"
                      alt=""
                    />
                    <span>{{ $t("cases.createImage.ck") }}</span>
                  </p>
                </div>
                <el-upload
                  class="upload-demo"
                  drag
                  :data="{ token: uploadToken, key: nanoid }"
                  :on-success="
                    res =>
                      uploadSuccess(
                        res,
                        'mouthLeftPicture',
                        $t('cases.createImage.knycyx')
                      )
                  "
                  :on-error="uploadError"
                  :before-upload="beforeUpload"
                  accept="image/jpeg,image/bmp,image/png"
                  :action="UPLOAD_URL"
                  :show-file-list="false"
                  list-type="picture"
                  v-show="!diagnosisParams.mouthLeftPicture['nanoId']"
                >
                  <div class="up_box">
                    <p class="up_box_tip">
                      <span class="up___tip_icon"></span
                      >{{ $t("cases.createImage.sctz") }}
                    </p>
                  </div>
                  <p
                    class="up_load_def up_load_def8"
                    v-show="!diagnosisParams.mouthLeftPicture['nanoId']"
                  ></p>
                </el-upload>
                <span
                  class="remove_pic"
                  v-show="diagnosisParams.mouthLeftPicture['nanoId']"
                  @click="() => removeFiledPic('mouthLeftPicture')"
                ></span>
              </div>
              <p class="up_txt">
                <span
                  v-if="queryProductType !== 'D'"
                  class="main_theme_color_red mr4"
                  >●</span
                >
                {{ $t("cases.createImage.knycyx") }}
              </p>
            </li>
          </template>
          <template v-else>
            <li class="up_li">
              <div class="up_load">
                <div
                  class="up_load_img"
                  @dragover.prevent
                  @drop.prevent="
                    event => {
                      handleDrop(
                        event,
                        'bitePicture',
                        $t('cases.createImage.yhx')
                      );
                    }
                  "
                  @click="
                    showCarousel({
                      filed: 'bitePicture',
                      text: $t('cases.createImage.yhx'),
                      index: getPicIndex('bitePicture')
                    })
                  "
                  v-show="diagnosisParams.bitePicture['nanoId']"
                >
                  <img
                    v-if="diagnosisParams.bitePicture['nanoId']"
                    :src="
                      `${$PicPrefix}${diagnosisParams.bitePicture['nanoId']}?imageView2/0/w/360/h/250`
                    "
                    :style="{
                      transform: `rotate(${
                        diagnosisParams.bitePicture['degree']
                      }deg) scaleY(${
                        diagnosisParams.bitePicture['vertical'] ? -1 : 1
                      }) scaleX(${
                        diagnosisParams.bitePicture['horizontal'] ? -1 : 1
                      })`
                    }"
                    alt=""
                  />
                  <p class="pic_look">
                    <img
                      src="/static/images/createCases/check_icon.png"
                      alt=""
                    />
                    <span>{{ $t("cases.createImage.ck") }}</span>
                  </p>
                </div>
                <el-upload
                  class="upload-demo"
                  drag
                  :data="{ token: uploadToken, key: nanoid }"
                  :on-success="
                    res =>
                      uploadSuccess(
                        res,
                        'bitePicture',
                        $t('cases.createImage.yhx')
                      )
                  "
                  :on-error="uploadError"
                  :before-upload="beforeUpload"
                  accept="image/jpeg,image/bmp,image/png"
                  :action="UPLOAD_URL"
                  :show-file-list="false"
                  list-type="picture"
                  v-show="!diagnosisParams.bitePicture['nanoId']"
                >
                  <div class="up_box">
                    <p class="up_box_tip">
                      <span class="up___tip_icon"></span
                      >{{ $t("cases.createImage.sctz") }}
                    </p>
                  </div>
                  <p
                    class="up_load_def up_load_def11"
                    v-show="!diagnosisParams.bitePicture['nanoId']"
                  ></p>
                </el-upload>
                <span
                  class="remove_pic"
                  v-show="diagnosisParams.bitePicture['nanoId']"
                  @click="() => removeFiledPic('bitePicture')"
                ></span>
              </div>
              <p class="up_txt">
                <span
                  v-if="queryProductType !== 'D'"
                  class="main_theme_color_red mr4"
                  >●</span
                >{{ $t("cases.createImage.yhx") }}
              </p>
            </li>
            <li class="up_li">
              <div class="up_load">
                <div
                  class="up_load_img"
                  @dragover.prevent
                  @drop.prevent="
                    event => {
                      handleDrop(
                        event,
                        'openAndClosePicture',
                        $t('cases.createImage.khx')
                      );
                    }
                  "
                  @click="
                    showCarousel({
                      filed: 'openAndClosePicture',
                      text: $t('cases.createImage.khx'),
                      index: getPicIndex('openAndClosePicture')
                    })
                  "
                  v-show="diagnosisParams.openAndClosePicture['nanoId']"
                >
                  <img
                    v-if="diagnosisParams.openAndClosePicture['nanoId']"
                    :src="
                      `${$PicPrefix}${diagnosisParams.openAndClosePicture['nanoId']}?imageView2/0/w/360/h/250`
                    "
                    :style="{
                      transform: `rotate(${
                        diagnosisParams.openAndClosePicture['degree']
                      }deg) scaleY(${
                        diagnosisParams.openAndClosePicture['vertical'] ? -1 : 1
                      }) scaleX(${
                        diagnosisParams.openAndClosePicture['horizontal']
                          ? -1
                          : 1
                      })`
                    }"
                    alt=""
                  />
                  <p class="pic_look">
                    <img
                      src="/static/images/createCases/check_icon.png"
                      alt=""
                    />
                    <span>{{ $t("cases.createImage.ck") }}</span>
                  </p>
                </div>
                <el-upload
                  class="upload-demo"
                  drag
                  :data="{ token: uploadToken, key: nanoid }"
                  :on-success="
                    res =>
                      uploadSuccess(
                        res,
                        'openAndClosePicture',
                        $t('cases.createImage.khx')
                      )
                  "
                  :on-error="uploadError"
                  :before-upload="beforeUpload"
                  accept="image/jpeg,image/bmp,image/png"
                  :action="UPLOAD_URL"
                  :show-file-list="false"
                  list-type="picture"
                  v-show="!diagnosisParams.openAndClosePicture['nanoId']"
                >
                  <div class="up_box">
                    <p class="up_box_tip">
                      <span class="up___tip_icon"></span
                      >{{ $t("cases.createImage.sctz") }}
                    </p>
                  </div>
                  <p
                    class="up_load_def up_load_def12"
                    v-show="!diagnosisParams.openAndClosePicture['nanoId']"
                  ></p>
                </el-upload>
                <span
                  class="remove_pic"
                  v-show="diagnosisParams.openAndClosePicture['nanoId']"
                  @click="() => removeFiledPic('openAndClosePicture')"
                ></span>
              </div>
              <p class="up_txt">
                <span
                  v-if="queryProductType !== 'D'"
                  class="main_theme_color_red mr4"
                  >●</span
                >{{ $t("cases.createImage.khx") }}
              </p>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div
      v-if="['A', 'B', 'C', 'E', 'I'].includes(queryProductType)"
      :id="disposeId('x')"
      class="image_single"
    >
      <div class="image_single_header">
        <span class="vertical_line"></span>
        <p class="fz18 main_theme_color_333 mr4">
          {{ $t("cases.createImage.xgp") }}
        </p>
        <span
          v-if="diagnosisParams['downjawFar'] || diagnosisParams['upjawFar']"
          class="main_theme_color_red mr10 fz14"
          >●</span
        >
        <img
          style="width: 12px"
          class="dib mr4"
          src="../../../../../common/imgs/case/creater_img_ask.png"
          alt=""
        />
        <router-link
          target="_blank"
          style="display: flex; align-items: center"
          :to="{ name: 'helpCenter', query: { type: '13' } }"
        >
          <span class="fz14 main_theme_color">{{
            $t("cases.createImage.xgpbz")
          }}</span>
        </router-link>
      </div>
      <div class="single_con">
        <p class="problem_note" v-if="diagnosisProblem[repulseNumber('x')]">
          {{ diagnosisProblem[repulseNumber("x")] }}
        </p>
        <ul class="up_ul" style="justify-content: left">
          <li class="up_li" style="margin-right: 0.96rem">
            <div class="up_load">
              <div
                class="up_load_img"
                @dragover.prevent
                @drop.prevent="
                  event => {
                    handleDrop(
                      event,
                      'xrayCurvePicture',
                      $t('cases.createImage.dcp')
                    );
                  }
                "
                @click="
                  showCarousel({
                    filed: 'xrayCurvePicture',
                    text: $t('cases.createImage.dcp'),
                    index: getPicIndex('xrayCurvePicture')
                  })
                "
                v-show="diagnosisParams.xrayCurvePicture['nanoId']"
              >
                <img
                  v-if="diagnosisParams.xrayCurvePicture['nanoId']"
                  :src="
                    `${$PicPrefix}${diagnosisParams.xrayCurvePicture['nanoId']}?imageView2/0/w/360/h/250`
                  "
                  :style="{
                    transform: `rotate(${
                      diagnosisParams.xrayCurvePicture['degree']
                    }deg) scaleY(${
                      diagnosisParams.xrayCurvePicture['vertical'] ? -1 : 1
                    }) scaleX(${
                      diagnosisParams.xrayCurvePicture['horizontal'] ? -1 : 1
                    })`
                  }"
                  alt=""
                />
                <p class="pic_look">
                  <img src="/static/images/createCases/check_icon.png" alt="" />
                  <span>{{ $t("cases.createImage.ck") }}</span>
                </p>
              </div>
              <el-upload
                class="upload-demo"
                drag
                :data="{ token: uploadToken, key: nanoid }"
                :on-success="
                  res =>
                    uploadSuccess(
                      res,
                      'xrayCurvePicture',
                      $t('cases.createImage.dcp')
                    )
                "
                :on-error="uploadError"
                :before-upload="beforeUpload"
                accept="image/jpeg,image/bmp,image/png"
                :action="UPLOAD_URL"
                :show-file-list="false"
                list-type="picture"
                v-show="!diagnosisParams.xrayCurvePicture['nanoId']"
              >
                <div class="up_box">
                  <p class="up_box_tip">
                    <span class="up___tip_icon"></span
                    >{{ $t("cases.createImage.sctz") }}
                  </p>
                </div>
                <p
                  class="up_load_def up_load_def9"
                  v-show="!diagnosisParams.xrayCurvePicture['nanoId']"
                ></p>
              </el-upload>
              <span
                class="remove_pic"
                v-show="diagnosisParams.xrayCurvePicture['nanoId']"
                @click="() => removeFiledPic('xrayCurvePicture')"
              ></span>
            </div>
            <p class="up_txt">{{ $t("cases.createImage.dcp") }}</p>
          </li>
          <li class="up_li">
            <div class="up_load">
              <div
                class="up_load_img"
                @dragover.prevent
                @drop.prevent="
                  event => {
                    handleDrop(
                      event,
                      'xraySidePicture',
                      $t('cases.createImage.dwp')
                    );
                  }
                "
                @click="
                  showCarousel({
                    filed: 'xraySidePicture',
                    text: $t('cases.createImage.dwp'),
                    index: getPicIndex('xraySidePicture')
                  })
                "
                v-show="diagnosisParams.xraySidePicture['nanoId']"
              >
                <div class="up_load_img_pic"></div>
                <img
                  v-if="diagnosisParams.xraySidePicture['nanoId']"
                  :src="
                    `${$PicPrefix}${diagnosisParams.xraySidePicture['nanoId']}?imageView2/0/w/360/h/250`
                  "
                  :style="{
                    transform: `rotate(${
                      diagnosisParams.xraySidePicture['degree']
                    }deg) scaleY(${
                      diagnosisParams.xraySidePicture['vertical'] ? -1 : 1
                    }) scaleX(${
                      diagnosisParams.xraySidePicture['horizontal'] ? -1 : 1
                    })`
                  }"
                  alt=""
                />
                <p class="pic_look">
                  <img src="/static/images/createCases/check_icon.png" alt="" />
                  <span>{{ $t("cases.createImage.ck") }}</span>
                </p>
              </div>
              <el-upload
                class="upload-demo"
                drag
                :data="{ token: uploadToken, key: nanoid }"
                :on-success="
                  res =>
                    uploadSuccess(
                      res,
                      'xraySidePicture',
                      $t('cases.createImage.dwp')
                    )
                "
                :on-error="uploadError"
                :before-upload="beforeUpload"
                accept="image/jpeg,image/bmp,image/png"
                :action="UPLOAD_URL"
                :show-file-list="false"
                list-type="picture"
                v-show="!diagnosisParams.xraySidePicture['nanoId']"
              >
                <div class="up_box">
                  <p class="up_box_tip">
                    <span class="up___tip_icon"></span
                    >{{ $t("cases.createImage.sctz") }}
                  </p>
                </div>
                <p
                  class="up_load_def up_load_def9"
                  v-show="!diagnosisParams.xraySidePicture['nanoId']"
                ></p>
              </el-upload>
              <span
                class="remove_pic"
                v-show="diagnosisParams.xraySidePicture['nanoId']"
                @click="() => removeFiledPic('xraySidePicture')"
              ></span>
            </div>
            <p class="up_txt">{{ $t("cases.createImage.dwp") }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="!isAestheticBraces"
      :id="disposeId('other')"
      class="image_single"
    >
      <div class="image_single_header">
        <span class="vertical_line"></span>
        <p class="fz18 main_theme_color_333 mr10">
          {{ $t("cases.createImage.yxzl") }}
        </p>
      </div>
      <div class="single_con">
        <p
          class="problem_note ml30"
          v-if="diagnosisProblem[repulseNumber('other')]"
        >
          {{ diagnosisProblem[repulseNumber("other")] }}
        </p>
        <p class="ml30">
          <span class="fz14 main_theme_color_333">{{
            $t("cases.createImage.ktztp")
          }}</span>
          <span class="c-red fz14">
            {{ $t("cases.createImage.zdten") }}
          </span>
        </p>
        <div class="other_img ml30">
          <div
            v-for="(other, uxd) in fileList"
            :key="uxd"
            @mouseenter="OtherImgMouseenter(uxd)"
            @mouseleave="isShowOtherItem = ''"
            @click.prevent.stop="
              showCarousel({
                filed: 'otherPicture',
                index: other.index,
                text: other.text
              })
            "
            @dragover.prevent
            @drop.prevent="
              event => {
                handleDrop(
                  event,
                  'otherPicture',
                  $t('cases.createImage.yxzl'),
                  uxd
                );
              }
            "
            v-show="fileList.length"
            class="img_item"
          >
            <img
              class="img-content"
              :src="`${$PicPrefix}${other['nanoId']}?imageView2/0/w/360/h/250`"
              :style="{
                transform: `rotate(${other['degree']}deg) scaleY(${
                  other['vertical'] ? -1 : 1
                }) scaleX(${other['horizontal'] ? -1 : 1})`
              }"
              alt=""
            />
            <div v-if="isShowOtherItem === uxd" class="icon">
              <i class="el-icon-view icon_font curp"></i>
              <span class="c-white">{{ $t("cases.createImage.ck") }}</span>
            </div>
            <div
              class="delete_icon"
              @click.self.prevent.stop="deleteOtherImg(uxd, other.index)"
            ></div>
          </div>
          <template v-if="fileList.length < 10">
            <el-upload
              class="upload-demo"
              ref="otherUpload"
              :data="{ token: uploadToken, key: nanoid }"
              :on-success="
                (res, file) =>
                  uploadSuccess(
                    res,
                    'otherPicture',
                    $t('cases.createImage.yxzl'),
                    file,
                    'other'
                  )
              "
              :on-error="uploadError"
              :on-exceed="pictureExceedHintMassage"
              :file-list="other_list"
              :limit="10"
              :show-file-list="false"
              :before-upload="beforeUpload"
              accept="image/jpeg,image/bmp,image/png"
              :on-remove="picture => handleRemove(picture)"
              :action="UPLOAD_URL"
              drag
              list-type="picture"
            >
              <div class="up_box">
                <p class="up_box_tip">
                  <span class="up___tip_icon"></span
                  >{{ $t("cases.createImage.sctz") }}
                </p>
              </div>
              <p class="other_bc_img up_load_def9"></p>
            </el-upload>
          </template>
        </div>
      </div>
    </div>
    <FullLoading v-show="isLoading" />

    <LookPic
      :canChange="true"
      @changeCarouselList="changeCarouselList"
      :curePlanId="$route.query.curePlanId"
      :uploadToken="uploadToken"
      ref="LookPic"
      :picKey="'index'"
      :carouselList="carouselList"
    />

    <el-dialog
      :title="$t('cases.createImage.lstj')"
      :visible.sync="historyFileVisible"
    >
      <div style="height: 4rem">
        <el-scrollbar style="height: 100%">
          <el-table :data="historyFileList">
            <el-table-column
              prop="createDatetime"
              :label="$t('home.home.sj')"
              width="200"
            ></el-table-column>
            <el-table-column
              prop="node"
              :label="$t('casesDetail.casesDetail.jd')"
              width="150"
            >
              <template slot-scope="scope">
                <div>
                  {{ stageDictObj[scope.row.stage] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="450"
              :label="$t('casesDetail.casesDetail.wjm')"
            >
              <template slot-scope="scope">
                <div>
                  {{
                    map(
                      scope.row.attachmentHisList,
                      item => item.realFileName
                    ).join(",")
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              :label="$t('common.common.xz')"
              width="100"
            >
              <template slot-scope="scope">
                <div @click="downFile(scope.row)" class="download_btn">
                  {{ $t("common.common.xz") }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>

      <span slot="footer" class="dialog-footer">
        <div class="cancel_btn" @click="historyFileVisible = false">
          {{ $t("casesDetail.casesChildren.common.qx") }}
        </div>
        <div class="sure_btn" @click="historyFileVisible = false">
          {{ $t("casesDetail.casesChildren.common.qd") }}
        </div>
      </span>
    </el-dialog>

    <PreviewImg
      :carouselList="carouselList"
      ref="previewImg"
      @imgChange="imgChange"
    />
  </div>
</template>

<script>
import * as QINIU from "qiniu-js";
import clipboard from "clipboard";
import { UPLOAD_URL } from "common/js/config";
import { getQiniuToken, getDictAllList } from "common/api/public";
import { notifyMsg, downloadFileList } from "common/js/util";
import FullLoading from "components/full-loading/full-loading";
import {
  PublicAttachmentLogList,
  cureProblemList,
  columnDataTempRecordDetail
} from "common/api/cases";
import { getDictList } from "common/api/public";
import { mapState, mapActions, mapGetters } from "vuex";
import {
  map,
  filter,
  isArray,
  extend,
  each,
  some,
  get,
  find,
  pickBy
} from "lodash";
import CDate from "common/js/CDate";
import LookPic from "components/lookPic-copy/index";
import PreviewImg from "components/preview-image/preview-image.vue";
import { nanoid } from "nanoid";

export default {
  data() {
    return {
      copyCases: null,
      isLoading: false,
      isSendExpress: false,
      dialogVisible: false,
      isShowOtherItem: "",
      other_list: [],
      UPLOAD_URL,
      uploadToken: "",
      dictObj: {},
      diagnosisProblem: {},
      problemList: [],
      caseNumber: "",
      diagnosisParams: {
        siliconeRubberModel: "",
        digitalModelFile: "",
        attachmentInfoList: [],
        sideFacePicture: "", // 侧面像
        frontFacePicture: "", // 正面像
        smilePicture: "", // 微笑像
        upToothPicture: "", // 上牙列像
        downToothPicture: "", // 下牙列像
        mouthRightPicture: "", // 口内右侧位像
        mouthFrontPicture: "", // 口内正位像
        mouthLeftPicture: "", // 口内左侧位像
        xrayCurvePicture: "", // 全颌曲面断层片
        xraySidePicture: "", // 头颅侧位定位片
        otherPicture: "", // 其他影像资料
        anteriorOverjetPicture: "", //前牙覆盖像
        bitePicture: "", // 咬合像
        openAndClosePicture: "" // 开合像
      },
      fileNameObj: {
        other_file: [],
        iTero: "",
        sharp_upjaw: "",
        sharp_downjaw: ""
      },
      diaKeyObj: {},
      carouselList: [],
      carouselName: "",
      carouselFiled: 0,
      picNum: 1,
      rotateNumX: 0,
      rotateNumY: 0,
      rotateNum: 0,
      fileList: [],
      isAestheticBraces: this.queryProductType === "D",
      isYysd: this.queryProductType === "F",
      isEditFileList: [],
      modeNoProblem: false,
      isResponse: 0,
      historyFileVisible: false,
      historyFileList: [],
      stageDictObj: {},
      currentImg: "",
      exDefaultKey: "",
      nanoid: nanoid(),
      idxObj: {
        sideFacePicture: 0,
        frontFacePicture: 1,
        smilePicture: 2,
        upToothPicture: 3,
        anteriorOverjetPicture: 4,
        downToothPicture: 5,
        mouthRightPicture: 6,
        mouthFrontPicture: 7,
        mouthLeftPicture: 8,
        xrayCurvePicture: 9,
        xraySidePicture: 10
      },
      yysdIdxObj: {
        sideFacePicture: 0,
        frontFacePicture: 1,
        smilePicture: 2,
        bitePicture: 3,
        openAndClosePicture: 4
      }
    };
  },
  props: {
    caseDetail: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState({
      language: state => state.language
    }),
    ...mapGetters({
      commonCaseCureDetail: "getCommonCaseCureDetail" //治疗表详情
    }),
    queryProductType() {
      return this.caseDetail["cureType"];
    },
    queryCaseId() {
      return this.$route.query.caseId;
    },
    queryCurePlanId() {
      return this.$route.query.curePlanId;
    }
  },
  methods: {
    ...mapActions({
      getCaseCureDetail: "actionGetCaseCureDetail" //治疗表详情
    }),
    map,
    get,
    handleDrop(event, filed, name, uxd) {
      const file = event.dataTransfer.files[0];
      this.nanoid = nanoid();
      const supportedFormats = ["image/jpeg", "image/png", "image/bmp"];
      let isLt20M = file.size / 1024 / 1024 < 20;
      if (!supportedFormats.includes(file.type)) {
        notifyMsg(this, "warning", this.$t("common.message.supportedFormats"));
        return false;
      } else if (!isLt20M) {
        notifyMsg(this, "warning", this.$t("common.message.isLt20M"));
        return false;
      } else {
        // this.isLoading = true;
        this.beforeDrop(file, filed, name, uxd);
      }
    },
    async beforeDrop(file, filed, name, uxd) {
      const params = await new Promise((resolve, reject) => {
        const formData = new FormData();
        // 在form实例中添加 file qiniuToekn 固定格式类名
        formData.append("file", file);
        formData.append("token", this.uploadToken);
        formData.append("key", nanoid());
        const fileName = file.name.replace(/\s/g, ""); // 去除空格
        const ts = new Date().getTime();
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "https://upload.qiniup.com/", true);
        // 携带登录token
        xhr.setRequestHeader("Authorization", localStorage.getItem("token"));

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // 根据组件 api 返回固定格式的 url
            const response = JSON.parse(xhr.responseText);
            // const alt = response.key;
            resolve(response);
          } else if (xhr.readyState === 4 && xhr.status !== 200) {
            reject("上传失败"); // 如果上传失败，返回错误信息
          }
        };
        xhr.send(formData);
      });
      if (filed !== "otherPicture") {
        this.carouselList = this.carouselList.filter(v => v?.filed !== filed);
        this.uploadSuccess(params, filed, name);
      } else {
        this.uploadSuccess(params, filed, name, file, "other", uxd + 1);
      }
    },
    imgChange(obj, idx) {
      const current = this.carouselList[idx];
      if (obj.filed !== "otherPicture") {
        this.diagnosisParams[current.filed] = {
          nanoId: this.diagnosisParams[current.filed].nanoId,
          degree: obj.degree,
          horizontal: obj.horizontal,
          vertical: obj.vertical,
          ETag: obj.ETag
        };
      } else {
        this.fileList.map(item => {
          if (item.index == current.index) {
            this.$set(item, "degree", obj.degree);
            this.$set(item, "horizontal", obj.horizontal);
            this.$set(item, "vertical", obj.vertical);
          }
        });
      }
    },
    changeCarouselList({ hash, Filed }) {
      this.carouselList = map(this.carouselList, item => {
        if (item.index === Filed) {
          if (item.filed === "otherPicture") {
            let otherPicListStr = map(
              filter(
                this.carouselList,
                other =>
                  other.filed === "otherPicture" && other["index"] !== Filed
              ),
              it => {
                let itList = it.href.split("/");
                return itList[itList.length - 1];
              }
            ).join(",");
            this.diagnosisParams.otherPicture = `${hash},${otherPicListStr}`;
            this.fileList = map(this.fileList, it => {
              if (it.index === Filed) {
                return extend({}, it, { href: this.$PicPrefix + hash });
              }
              return it;
            });
          } else if (item.filed in this.diagnosisParams) {
            this.diagnosisParams[item.filed] = hash;
          }
          return {
            ...item,
            href: this.$PicPrefix + hash
          };
        }
        return item;
      });
    },
    tabsChanged(key) {
      this.diagnosisParams.siliconeRubberModel = key;
    },
    showModel(key) {
      return key === "self_deliver"
        ? +get(this.caseDetail, "selfDeliverFlag") === 1
        : true;
    },

    disposeId(type) {
      return (
        {
          A: {
            model: 20,
            face: 21,
            x: 22,
            other: 23
          }[type],
          B: {
            model: 22,
            face: 23,
            x: 24,
            other: 25
          }[type],
          C: {
            model: 20,
            face: 21,
            x: 22,
            other: 23
          }[type],
          D: {
            model: 4,
            face: 5,
            x: 6,
            other: 7
          }[type],
          E: {
            model: 21,
            face: 22,
            x: 23,
            other: 24
          }[type],
          F: {
            model: 15,
            face: 16,
            x: 17,
            other: 18
          }[type]
        }[this.queryProductType] || ""
      );
    },
    jumpLocation(number) {
      document.documentElement.scrollTop = document.getElementById(
        number
      ).offsetTop;
    },
    downFile(item) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      let name = `${this.caseNumber}_${
        this.diagnosisParams.userInfo.realName
      }_${new CDate().format("yyyy-MM-dd")}`;
      downloadFileList(item.attachmentHisList, true, name, this.$PicPrefix);
      loading.close();
    },
    getDictList() {
      getDictList("case.stage").then(data => {
        each(data, item => {
          this.stageDictObj[item.key] = item.value;
        });
      });
    },
    getHistoryFileList() {
      return PublicAttachmentLogList({
        caseId: this.$route.query.caseId,
        nodeList: ["to_submit", "to_resubmit"]
      }).then(data => {
        this.historyFileList = data;
      });
    },
    lookHistoryFile() {
      this.historyFileVisible = true;
    },
    otherListInit() {
      this.$message.warning(this.$t("cases.createImage.zdxz"));
    },
    deleteOtherFile(index, id) {
      if (this.modeNoProblem) return;
      this.fileNameObj["other_file"].splice(index, 1);
      this.diagnosisParams.attachmentInfoList = filter(
        map(this.diagnosisParams.attachmentInfoList, item => {
          if (item.id === id) {
            return;
          }
          return item;
        })
      );
    },
    repulseNumber(type) {
      return {
        model: {
          A: 20,
          B: 22,
          C: 20,
          D: 4,
          E: 21,
          F: 17
        }[this.queryProductType],
        face: {
          A: 21,
          B: 23,
          C: 21,
          D: 5,
          E: 22,
          F: 15
        }[this.queryProductType],
        x: {
          A: 22,
          B: 24,
          C: 22,
          D: 6,
          E: 23,
          F: 17
        }[this.queryProductType],
        other: {
          A: 23,
          B: 25,
          C: 23,
          D: 7,
          E: 24,
          F: 16
        }[this.queryProductType]
      }[type];
    },
    getPicIndex(filed) {
      return filter(this.carouselList, item => item.filed === filed)[0].index;
    },
    showCarousel(item) {
      this.$nextTick(() => {
        this.$refs["previewImg"] &&
          this.$refs["previewImg"].show(item.text, item.index);
      });
    },
    copyCasesNumber() {
      notifyMsg(this, "success", this.$t("cases.createImage.fzcg"));
    },
    selectedProblem(ev, filed) {
      if (this.modeNoProblem) return;
      const target = ev.target;
      if (target.tagName.toLocaleLowerCase() === "span") {
        const val = target.getAttribute("data-key");
        if (filed === "siliconeRubberModel") {
          this.diagnosisParams.digitalModelFile = "";
          this.fileNameObj = {
            other_file: [],
            iTero: "",
            sharp_upjaw: "",
            sharp_downjaw: ""
          };
          this.exDefaultKey = val;
          if (val === "self_deliver") {
            this.$refs["exBirdie"].openDia().then(() => {
              this.saveData();
            });
          }
        }
        if (filed === "digitalModelFile") {
          this.diagnosisParams.siliconeRubberModel = "";
        }
        if (this.diagnosisParams[filed] !== val) {
          this.remarkParams = {
            ...this.remarkParams,
            [filed]: null
          };
          this.remarkObj = {
            ...this.remarkObj,
            [filed]: null
          };
          this.diagnosisParams = {
            ...this.diagnosisParams,
            [filed]: val
          };
        } else {
          if (filed !== "siliconeRubberModel") {
            delete this.diagnosisParams[filed];
            this.diagnosisParams = {
              ...this.diagnosisParams
            };
          }
        }
        if (filed === "digitalModelFile") {
          window.scrollTo(0, 0);
        }
      }
    },
    beforeFileUpload(file, key, type, tabKey) {
      const { name } = file;
      const _this = this;
      if (name.indexOf(type) > 0) {
        this.isLoading = true;
        const { attachmentInfoList } = _this.diagnosisParams;
        const list =
          key !== "other_file"
            ? attachmentInfoList.filter(item => item.fileKey !== key)
            : attachmentInfoList;
        const aBlob = new Blob([file]);
        const ts = new Date().getTime();
        const observable = QINIU.upload(
          aBlob,
          ts + "_" + name,
          this.uploadToken
        );
        observable.subscribe({
          error(err) {
            _this.isLoading = false;
          },
          complete(res) {
            const { hash } = res;
            _this.isLoading = false;
            _this.diagnosisParams.attachmentInfoList = [
              ...list,
              {
                fileKey: key,
                fileName: ts + "_" + name,
                realFileName: name,
                fileType: type,
                fileUrl: hash,
                fileSource: "DOCTOR"
              }
            ];
            _this.fileNameObj[key] =
              key !== "other_file"
                ? ts + "_" + name
                : [..._this.fileNameObj[key], { name: ts + "_" + name }];
            notifyMsg(_this, "success", _this.$t("common.message.wjsccg"));
          }
        });
      } else {
        notifyMsg(
          this,
          "warning",
          `${this.$t("common.message.qxz")}${type}${this.$t(
            "common.message.wjsc"
          )}`
        );
      }
      return false;
    },
    //删除其他影像图片
    deleteOtherImg(index, carouseIndex) {
      this.carouselList.splice(carouseIndex, 1);
      this.other_list.splice(index, 1);
      this.carouselList.map((item, index) => {
        this.carouselList[index].index = index + "";
      });
      let list = [];
      this.carouselList.map(item => {
        if (item.filed === "otherPicture") {
          list.push({
            href: item.href,
            index: item.index,
            text: item.name,
            nanoId: item.nanoId,
            ETag: item.ETag,
            degree: item.degree,
            horizontal: item.horizontal,
            vertical: item.vertical
          });
        }
      });
      this.fileList = list;
    },
    OtherImgMouseenter(index) {
      this.isShowOtherItem = index;
    },
    pictureExceedHintMassage() {
      return notifyMsg(this, "warning", this.$t("cases.createImage.zdten"));
    },
    isJSON(str) {
      if (str == "") {
        return "";
      }
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          let obj = JSON.parse(str);
          !obj.nanoId && (obj.nanoId = obj.ETag);
          !obj.degree && (obj.degree = 0);
          !obj.horizontal && (obj.horizontal = false);
          !obj.vertical && (obj.vertical = false);
          return obj;
        } catch (error) {
          return {
            ETag: str,
            nanoId: str,
            degree: 0,
            horizontal: false,
            vertical: false
          };
        }
      }
    },
    uploadSuccess(res, filed, name, file, type, i) {
      this.isLoading = false;
      const { diagnosisParams } = this;
      if (type !== "other") {
        diagnosisParams[filed] = { ETag: res.key, nanoId: res.key };
      }
      let index = this.carouselList.length;
      if (filed !== "otherPicture") {
        this.carouselList.splice(
          this.queryProductType == "F"
            ? this.yysdIdxObj[filed]
            : this.idxObj[filed],
          0,
          {
            nanoId: res.key,
            name,
            ETag: res.key,
            href: this.$PicPrefix + res.hash,
            filed
          }
        );
      } else {
        if (!i) {
          this.carouselList.push({
            nanoId: res.key,
            name,
            ETag: res.key,
            href: this.$PicPrefix + res.hash,
            filed,
            index: index + ""
          });
        } else {
          const _etag = this.fileList[i - 1]?.ETag;
          this.carouselList = this.carouselList?.map(v => {
            if (_etag === v?.ETag) {
              return {
                ...v,
                ETag: res.key,
                href: this.$PicPrefix + res.hash,
                nanoId: res.key
              };
            } else {
              return v;
            }
          });
        }
      }
      this.carouselList.map((item, index) => {
        this.carouselList[index].index = index + "";
      });
      if (type === "other") {
        if (i) {
          const _etag = this.fileList[i - 1]?.ETag;
          this.fileList = this.fileList?.map(v => {
            if (_etag === v?.ETag) {
              return {
                ...v,
                ETag: res.key,
                href: this.$PicPrefix + res.hash,
                nanoId: res.key
              };
            } else {
              return v;
            }
          });
        } else {
          this.fileList.push({
            nanoId: res.key,
            name,
            ETag: res.key,
            href: this.$PicPrefix + res.hash,
            filed,
            index: index + ""
          });
        }
      }
      notifyMsg(this, "success", this.$t("common.message.tpsccg"));
    },
    uploadError() {
      this.isLoading = false;
      return notifyMsg(this, "error", this.$t("common.message.scsb"));
    },
    handleRemove(img) {
      let url = img.response.hash;
      let index = this.fileList.indexOf(url);
      this.fileList.splice(index, 1);
    },
    beforeUpload(file) {
      this.nanoid = nanoid();
      const supportedFormats = ["image/jpeg", "image/png", "image/bmp"];
      let isLt20M = file.size / 1024 / 1024 < 20;
      if (!supportedFormats.includes(file.type)) {
        notifyMsg(this, "warning", this.$t("common.message.supportedFormats"));
        return false;
      } else if (!isLt20M) {
        notifyMsg(this, "warning", this.$t("common.message.isLt20M"));
        return false;
      } else {
        this.isLoading = true;
        return true;
      }
    },
    removeFiledPic(filed) {
      const { diagnosisParams } = this;
      this.diagnosisParams = {
        ...diagnosisParams,
        [filed]: ""
      };
      this.carouselList = this.carouselList.filter(
        item => item.filed !== filed
      );
      this.carouselList.map((item, index) => {
        this.carouselList[index].index = index + "";
      });
    },
    saveData() {
      const otherPic = map(this.fileList, it => {
        return {
          nanoId: it.nanoId,
          ETag: it.ETag,
          degree: it.degree || 0,
          horizontal: it.horizontal || false,
          vertical: it.vertical || false
        };
      });
      this.diagnosisParams = {
        ...this.diagnosisParams,
        otherPicture: JSON.stringify(otherPic)
      };
      let resultObj = JSON.parse(JSON.stringify(this.diagnosisParams));
      resultObj["sideFacePicture"] =
        resultObj["sideFacePicture"] &&
        JSON.stringify(resultObj["sideFacePicture"]);
      resultObj["frontFacePicture"] =
        resultObj["frontFacePicture"] &&
        JSON.stringify(resultObj["frontFacePicture"]);
      resultObj["smilePicture"] =
        resultObj["smilePicture"] && JSON.stringify(resultObj["smilePicture"]);
      resultObj["anteriorOverjetPicture"] =
        resultObj["anteriorOverjetPicture"] &&
        JSON.stringify(resultObj["anteriorOverjetPicture"]);
      resultObj["upToothPicture"] =
        resultObj["upToothPicture"] &&
        JSON.stringify(resultObj["upToothPicture"]);
      resultObj["downToothPicture"] =
        resultObj["downToothPicture"] &&
        JSON.stringify(resultObj["downToothPicture"]);
      resultObj["mouthRightPicture"] =
        resultObj["mouthRightPicture"] &&
        JSON.stringify(resultObj["mouthRightPicture"]);
      resultObj["mouthFrontPicture"] =
        resultObj["mouthFrontPicture"] &&
        JSON.stringify(resultObj["mouthFrontPicture"]);
      resultObj["mouthLeftPicture"] =
        resultObj["mouthLeftPicture"] &&
        JSON.stringify(resultObj["mouthLeftPicture"]);
      resultObj["xrayCurvePicture"] =
        resultObj["xrayCurvePicture"] &&
        JSON.stringify(resultObj["xrayCurvePicture"]);
      resultObj["xraySidePicture"] =
        resultObj["xraySidePicture"] &&
        JSON.stringify(resultObj["xraySidePicture"]);
      resultObj["bitePicture"] =
        resultObj["bitePicture"] && JSON.stringify(resultObj["bitePicture"]);
      resultObj["openAndClosePicture"] =
        resultObj["openAndClosePicture"] &&
        JSON.stringify(resultObj["openAndClosePicture"]);
      return pickBy(resultObj);
    },
    clickSharpUpJaw(ref) {
      if (this.modeNoProblem) return;
      this.$refs[ref].$el.childNodes[0] &&
        this.$refs[ref].$el.childNodes[0].click();
    },
    getDetail() {
      let id = this.queryCurePlanId;
      if (id) {
        this.diagnosisParams.id = id;
        const caseId = this.$route.query.caseId;
        const curePlanId = this.$route.query.curePlanId;
        columnDataTempRecordDetail({ caseId, curePlanId, type: "open_rdt" })
          .then(obj => {
            const finalResult = Object.fromEntries(
              Object.entries(obj["columnData"]).filter(
                ([key, value]) =>
                  value !== null &&
                  value !== undefined &&
                  Object.keys(value).length !== 0
              )
            );
            try {
              JSON.parse(finalResult.otherPicture);
              this.other_list = map(
                JSON.parse(finalResult.otherPicture),
                it => {
                  let finalResult = it;
                  if (!it.nanoId) {
                    it.nanoId = it.ETag;
                  }
                  this.fileList.push(finalResult);
                  return {
                    url: it
                  };
                }
              );
            } catch (error) {
              const arr = finalResult.otherPicture
                ? finalResult.otherPicture.split(",")
                : [];
              this.other_file = map(arr, it => {
                this.fileList.push({ ETag: it, nanoId: it });
                return {
                  url: it
                };
              });
            }
            this.diagnosisParams = {
              ...this.diagnosisParams,
              ...finalResult
            };
            this.isLoading = false;
            sessionStorage.setItem(
              "diagnosisParams",
              JSON.stringify(this.diagnosisParams)
            );
            this.diagnosisParams["sideFacePicture"] = this.isJSON(
              this.diagnosisParams["sideFacePicture"]
            );
            this.diagnosisParams["frontFacePicture"] = this.isJSON(
              this.diagnosisParams["frontFacePicture"]
            );
            this.diagnosisParams["smilePicture"] = this.isJSON(
              this.diagnosisParams["smilePicture"]
            );
            this.diagnosisParams["upToothPicture"] = this.isJSON(
              this.diagnosisParams["upToothPicture"]
            );
            this.diagnosisParams["downToothPicture"] = this.isJSON(
              this.diagnosisParams["downToothPicture"]
            );
            this.diagnosisParams["mouthRightPicture"] = this.isJSON(
              this.diagnosisParams["mouthRightPicture"]
            );
            this.diagnosisParams["mouthFrontPicture"] = this.isJSON(
              this.diagnosisParams["mouthFrontPicture"]
            );
            this.diagnosisParams["mouthLeftPicture"] = this.isJSON(
              this.diagnosisParams["mouthLeftPicture"]
            );
            this.diagnosisParams["xrayCurvePicture"] = this.isJSON(
              this.diagnosisParams["xrayCurvePicture"]
            );
            this.diagnosisParams["xraySidePicture"] = this.isJSON(
              this.diagnosisParams["xraySidePicture"]
            );
            this.diagnosisParams["anteriorOverjetPicture"] = this.isJSON(
              this.diagnosisParams["anteriorOverjetPicture"]
            );
            this.diagnosisParams["bitePicture"] = this.isJSON(
              this.diagnosisParams["bitePicture"]
            );
            this.diagnosisParams["openAndClosePicture"] = this.isJSON(
              this.diagnosisParams["openAndClosePicture"]
            );
            const {
              attachmentList = [],
              caseInfo = {},
              sideFacePicture,
              frontFacePicture,
              smilePicture,
              upToothPicture,
              downToothPicture,
              mouthRightPicture,
              mouthFrontPicture,
              mouthLeftPicture,
              xrayCurvePicture,
              xraySidePicture,
              anteriorOverjetPicture,
              bitePicture,
              openAndClosePicture
            } = obj;
            this.caseNumber = caseInfo.caseNumber;
            this.carouselList = [
              sideFacePicture
                ? {
                    href: this.$PicPrefix + this.isJSON(sideFacePicture).ETag,
                    filed: "sideFacePicture",
                    name:
                      this.queryProductType !== "F"
                        ? this.$t("cases.createImage.cmx")
                        : this.$t("cases.createImage.zcmwxx"),
                    ETag:
                      this.isJSON(sideFacePicture).ETag ||
                      this.isJSON(sideFacePicture).nanoId,
                    nanoId:
                      this.isJSON(sideFacePicture).nanoId ||
                      this.isJSON(sideFacePicture).ETag,
                    degree: this.isJSON(sideFacePicture).degree,
                    horizontal: this.isJSON(sideFacePicture).horizontal,
                    vertical: this.isJSON(sideFacePicture).vertical
                  }
                : "",
              frontFacePicture
                ? {
                    href: this.$PicPrefix + this.isJSON(frontFacePicture).ETag,
                    filed: "frontFacePicture",
                    name:
                      this.queryProductType !== "F"
                        ? this.$t("cases.createImage.zmx")
                        : this.$t("cases.createImage.zmwxx"),
                    ETag:
                      this.isJSON(frontFacePicture).ETag ||
                      this.isJSON(frontFacePicture).nanoId,
                    nanoId:
                      this.isJSON(frontFacePicture).nanoId ||
                      this.isJSON(frontFacePicture).ETag,
                    degree: this.isJSON(frontFacePicture).degree,
                    horizontal: this.isJSON(frontFacePicture).horizontal,
                    vertical: this.isJSON(frontFacePicture).vertical
                  }
                : "",
              smilePicture
                ? {
                    href: this.$PicPrefix + this.isJSON(smilePicture).ETag,
                    filed: "smilePicture",
                    name:
                      this.queryProductType !== "F"
                        ? this.$t("cases.createImage.wxx")
                        : this.$t("cases.createImage.ycmwxx"),
                    ETag:
                      this.isJSON(smilePicture).ETag ||
                      this.isJSON(smilePicture).nanoId,
                    nanoId:
                      this.isJSON(smilePicture).nanoId ||
                      this.isJSON(smilePicture).ETag,
                    degree: this.isJSON(smilePicture).degree,
                    horizontal: this.isJSON(smilePicture).horizontal,
                    vertical: this.isJSON(smilePicture).vertical
                  }
                : "",
              upToothPicture
                ? {
                    href: this.$PicPrefix + this.isJSON(upToothPicture).ETag,
                    filed: "upToothPicture",
                    name: this.$t("cases.createImage.sylx"),
                    ETag:
                      this.isJSON(upToothPicture).ETag ||
                      this.isJSON(upToothPicture).nanoId,
                    nanoId:
                      this.isJSON(upToothPicture).nanoId ||
                      this.isJSON(upToothPicture).ETag,
                    degree: this.isJSON(upToothPicture).degree,
                    horizontal: this.isJSON(upToothPicture).horizontal,
                    vertical: this.isJSON(upToothPicture).vertical
                  }
                : "",
              anteriorOverjetPicture
                ? {
                    href:
                      this.$PicPrefix +
                      this.isJSON(anteriorOverjetPicture).ETag,
                    filed: "anteriorOverjetPicture",
                    name: this.$t("cases.createImage.qyfgx"),
                    ETag:
                      this.isJSON(anteriorOverjetPicture).ETag ||
                      this.isJSON(anteriorOverjetPicture).nanoId,
                    nanoId:
                      this.isJSON(anteriorOverjetPicture).nanoId ||
                      this.isJSON(anteriorOverjetPicture).ETag,
                    degree: this.isJSON(anteriorOverjetPicture).degree,
                    horizontal: this.isJSON(anteriorOverjetPicture).horizontal,
                    vertical: this.isJSON(anteriorOverjetPicture).vertical
                  }
                : "",
              downToothPicture
                ? {
                    href: this.$PicPrefix + this.isJSON(downToothPicture).ETag,
                    filed: "downToothPicture",
                    name: this.$t("cases.createImage.xylx"),
                    ETag:
                      this.isJSON(downToothPicture).ETag ||
                      this.isJSON(downToothPicture).nanoId,
                    nanoId:
                      this.isJSON(downToothPicture).nanoId ||
                      this.isJSON(downToothPicture).ETag,
                    degree: this.isJSON(downToothPicture).degree,
                    horizontal: this.isJSON(downToothPicture).horizontal,
                    vertical: this.isJSON(downToothPicture).vertical
                  }
                : "",
              mouthRightPicture
                ? {
                    href: this.$PicPrefix + this.isJSON(mouthRightPicture).ETag,
                    filed: "mouthRightPicture",
                    name: this.$t("cases.createImage.knzcyx"),
                    ETag:
                      this.isJSON(mouthRightPicture).ETag ||
                      this.isJSON(mouthRightPicture).nanoId,
                    nanoId:
                      this.isJSON(mouthRightPicture).nanoId ||
                      this.isJSON(mouthRightPicture).ETag,
                    degree: this.isJSON(mouthRightPicture).degree,
                    horizontal: this.isJSON(mouthRightPicture).horizontal,
                    vertical: this.isJSON(mouthRightPicture).vertical
                  }
                : "",
              mouthFrontPicture
                ? {
                    href: this.$PicPrefix + this.isJSON(mouthFrontPicture).ETag,
                    filed: "mouthFrontPicture",
                    name: this.$t("cases.createImage.knzwx"),
                    ETag:
                      this.isJSON(mouthFrontPicture).ETag ||
                      this.isJSON(mouthFrontPicture).nanoId,
                    nanoId:
                      this.isJSON(mouthFrontPicture).nanoId ||
                      this.isJSON(mouthFrontPicture).ETag,
                    degree: this.isJSON(mouthFrontPicture).degree,
                    horizontal: this.isJSON(mouthFrontPicture).horizontal,
                    vertical: this.isJSON(mouthFrontPicture).vertical
                  }
                : "",
              mouthLeftPicture
                ? {
                    href: this.$PicPrefix + this.isJSON(mouthLeftPicture).ETag,
                    filed: "mouthLeftPicture",
                    name: this.$t("cases.createImage.knycyx"),
                    ETag:
                      this.isJSON(mouthLeftPicture).ETag ||
                      this.isJSON(mouthLeftPicture).nanoId,
                    nanoId:
                      this.isJSON(mouthLeftPicture).nanoId ||
                      this.isJSON(mouthLeftPicture).ETag,
                    degree: this.isJSON(mouthLeftPicture).degree,
                    horizontal: this.isJSON(mouthLeftPicture).horizontal,
                    vertical: this.isJSON(mouthLeftPicture).vertical
                  }
                : "",
              xrayCurvePicture
                ? {
                    href: this.$PicPrefix + this.isJSON(xrayCurvePicture).ETag,
                    filed: "xrayCurvePicture",
                    name: this.$t("cases.createImage.dcp"),
                    ETag:
                      this.isJSON(xrayCurvePicture).ETag ||
                      this.isJSON(xrayCurvePicture).nanoId,
                    nanoId:
                      this.isJSON(xrayCurvePicture).nanoId ||
                      this.isJSON(xrayCurvePicture).ETag,
                    degree: this.isJSON(xrayCurvePicture).degree,
                    horizontal: this.isJSON(xrayCurvePicture).horizontal,
                    vertical: this.isJSON(xrayCurvePicture).vertical
                  }
                : "",
              xraySidePicture
                ? {
                    href: this.$PicPrefix + this.isJSON(xraySidePicture).ETag,
                    filed: "xraySidePicture",
                    name: this.$t("cases.createImage.dwp"),
                    ETag:
                      this.isJSON(xraySidePicture).ETag ||
                      this.isJSON(xraySidePicture).nanoId,
                    nanoId:
                      this.isJSON(xraySidePicture).nanoId ||
                      this.isJSON(xraySidePicture).ETag,
                    degree: this.isJSON(xraySidePicture).degree,
                    horizontal: this.isJSON(xraySidePicture).horizontal,
                    vertical: this.isJSON(xraySidePicture).vertical
                  }
                : "",
              bitePicture
                ? {
                    href: this.$PicPrefix + this.isJSON(bitePicture).ETag,
                    filed: "bitePicture",
                    name: this.$t("cases.createImage.yhx"),
                    ETag:
                      this.isJSON(bitePicture).ETag ||
                      this.isJSON(bitePicture).nanoId,
                    nanoId:
                      this.isJSON(bitePicture).nanoId ||
                      this.isJSON(bitePicture).ETag,
                    degree: this.isJSON(bitePicture).degree,
                    horizontal: this.isJSON(bitePicture).horizontal,
                    vertical: this.isJSON(bitePicture).vertical
                  }
                : "",
              openAndClosePicture
                ? {
                    href:
                      this.$PicPrefix + this.isJSON(openAndClosePicture).ETag,
                    filed: "openAndClosePicture",
                    name: this.$t("cases.createImage.khx"),
                    ETag:
                      this.isJSON(openAndClosePicture).ETag ||
                      this.isJSON(openAndClosePicture).nanoId,
                    nanoId:
                      this.isJSON(openAndClosePicture).nanoId ||
                      this.isJSON(openAndClosePicture).ETag,
                    degree: this.isJSON(openAndClosePicture).degree,
                    horizontal: this.isJSON(openAndClosePicture).horizontal,
                    vertical: this.isJSON(openAndClosePicture).vertical
                  }
                : ""
            ].filter(item => !!item.href);
            this.carouselList = [
              ...this.carouselList,
              ...map(this.fileList, item => {
                return {
                  nanoId: item.nanoId || item.ETag,
                  href: this.$PicPrefix + item.ETag,
                  ETag: item.ETag,
                  filed: "otherPicture",
                  name: this.$t("cases.createImage.yxzl"),
                  degree: item.degree,
                  horizontal: item.horizontal,
                  vertical: item.vertical
                };
              })
            ];
            this.carouselList = map(this.carouselList, (item, index) => {
              return extend({}, item, { index: index + "" });
            });
            let otherIndexList = [];
            each(this.carouselList, (item, index) => {
              if (item.filed === "otherPicture") {
                otherIndexList.push(index);
              }
            });
            this.fileList = map(this.fileList, (item, index) => {
              return extend(
                {},
                {
                  nanoId: item.nanoId,
                  href: this.$PicPrefix + item.ETag,
                  index: otherIndexList[index] + "",
                  ETag: item.ETag,
                  text: this.$t("casesDetail.publicCases.casesPic.qtyx"),
                  degree: item.degree || 0,
                  horizontal: item.horizontal || false,
                  vertical: item.vertical || false
                }
              );
            });
            this.isEditFileList = attachmentList;
            if (attachmentList) {
              this.diagnosisParams.attachmentInfoList = attachmentList;
            }
            attachmentList.forEach(item => {
              const obj = {
                name: item.fileName,
                url: `${this.$PicPrefix}${item.fileUrl}`,
                id: item.id
              };
              if (item.fileKey !== "other_file") {
                this.fileNameObj[item.fileKey] = item.fileName;
              } else {
                this.fileNameObj["other_file"].push({
                  name: item.fileName,
                  id: item.id
                });
              }
            });
          })
          .catch(err => {
            this.isLoading = false;
          });
      }
    },
    getConfig() {
      getQiniuToken().then(data => {
        this.uploadToken = data;
      });
    }
  },
  components: {
    FullLoading,
    // TheDelivery,
    // ExBirdie,
    LookPic,
    PreviewImg
  },
  created() {
    this.isLoading = true;
    this.isResponse += 1;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.diagnosisParams.caseId = this.queryCaseId;
    this.getHistoryFileList();
    this.getDictList();
    this.getDetail();
    this.getConfig();
  },
  mounted() {
    if (this.$refs.copyCases) {
      this.copyCases = new clipboard(this.$refs.copyCases);
    }
  }
};
</script>

<style lang="scss" scoped>
.current_img {
  position: fixed;
  bottom: 999rem;
  left: 999rem;
}

.other_img {
  margin-top: 0.1rem;
  display: flex;
  flex-flow: row wrap;

  .other_bc_img {
    width: 100%;
    background-image: url("/static/images/createCases/select.png");
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0.73rem;
    right: 0;
    height: 1rem;
    z-index: 9;
    background-size: 100% 100%;
  }

  .img_item {
    cursor: pointer;
    width: 3.44rem;
    height: 2.4rem;
    background-color: #f3f3f3;
    margin-right: 0.3rem;
    margin-bottom: 0.2rem;
    border-radius: 0.05rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &:hover .icon {
      opacity: 1;
    }

    .img-content {
      position: relative;
      max-width: 100%;
      max-height: 100%;
    }

    .icon {
      width: 100%;
      height: 25%;
      background-color: rgba(0, 0, 0, 0.6);
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      transition: opacity 0.6s;

      .icon_font {
        font-size: 0.25rem;
        color: white;
        margin-right: 0.1rem;
      }
    }

    .delete_icon {
      width: 0.3rem;
      height: 0.3rem;
      background-image: url("/static/images/createCases/delet_img.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      cursor: pointer;
      position: absolute;
      right: 0.1rem;
      top: 0.1rem;
      z-index: 99;
    }
  }
}

.create_image {
  background-color: #fff;
  border-radius: 0.08rem;
  padding: 0 0.3rem 60px 0.3rem;
  color: #333;

  .dia_problem {
    width: 100%;
    background: #f4f4f4;
    padding: 10px 16px 30px;
    transform: translateY(30px);

    h5 {
      font-size: 0.22rem;
      color: $main_theme_color_333;
    }

    span {
      margin-right: 0.2rem;
    }

    .pro_box {
      word-break: break-all;
      font-size: 0.16rem;
      line-height: 0.3rem;
      color: $main_theme_color_333;
    }
  }

  .image_single {
    margin-bottom: 0.5rem;

    .image_single_header {
      display: flex;
      height: 0.58rem;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 10px;
    }

    .vertical_line {
      width: 4px;
      height: 26px;
    }

    .single_tit {
      font-size: 0.18rem;
      display: flex;
      align-items: center;

      .tit_icon {
        display: inline-block;
        width: 0.16rem;
        height: 0.16rem;
        border-radius: 100%;
        background-image: url("/static/images/createCases/question.png");
        background-size: 100% 100%;
        margin-right: 0.06rem;
        margin-left: 0.16rem;
      }
    }

    .single_con {
      .problem_note {
        word-break: break-all;
        color: $main_theme_color;
        margin-bottom: 0.3rem;
        line-height: 0.22rem;
        font-size: 0.14rem;
      }

      .s_c_box {
        display: flex;
        margin-bottom: 30px;

        .s_c__left {
          min-width: 0.96rem;
          font-size: 0.16rem;
          color: $main_theme_color_333;
          display: flex;
          flex-flow: row-reverse;
          margin-top: 10px;
        }

        .s_c__right {
          position: relative;
          padding-left: 20px;

          .history_submit_btn {
            position: absolute;
            top: 0.1rem;
            right: -1rem;
            color: #409eff;
            cursor: pointer;
          }

          .s_c__tip {
            width: 4.36rem;
            height: 68px;
            padding: 0.16rem 0.2rem;
            background-color: #f4f4f4;
            border-radius: 4px;
            position: relative;
            display: flex;
            align-items: center;

            .tip_sp_icon {
              display: inline-block;
              width: 0.24rem;
              height: 0.24rem;
              background-image: url("../../../../../common/imgs/case/case_icon_plus.png");
              background-size: 100% 100%;
              cursor: pointer;
            }

            .s_c_fkd {
              margin-left: 50px;
              border: 0.01rem solid $main_theme_color;
              border-radius: 0.06rem;
              width: 1.16rem;
              height: 0.36rem;
              padding: 0 0.1rem;
              line-height: 0.5rem;
              color: $main_theme_color_333;
              background-color: $main_theme_color;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              .fkd_icon {
                display: inline-block;
                width: 0.24rem;
                height: 0.24rem;
                background-image: url("/static/images/createCases/pc_doc_shunfeng.png");
                background-size: 100% 100%;
                border-radius: 100%;
                margin-right: 0.04rem;
              }
            }
          }

          .s_c__up {
            display: flex;
            align-items: center;
            font-weight: normal;
            margin-bottom: 0.16rem;
            margin-top: 20px;

            .upload-file {
              margin: 0 0.2rem;
            }

            .up_load {
              display: inline-block;
              height: 0.36rem;
              line-height: 0.36rem;
              border-radius: 0.03rem;
              outline: none;
              padding-right: 0.4rem;
              margin-left: 10px;
              position: relative;
            }

            .up_icon {
              display: inline-block;
              width: 0.24rem;
              height: 0.24rem;
              background-image: url("/static/images/createCases/web_doc_upload.png");
              background-size: 100% 100%;
              cursor: pointer;
            }

            .up_file {
              display: flex;
              align-items: center;

              .up_status {
                color: #5cb6ff;
                margin-left: 0.1rem;
              }
            }

            .other_file {
              margin-bottom: 0.1rem;
            }
          }
        }
      }

      .s_c__r_box {
        display: flex;
        align-items: center;

        .s_c__sp {
          min-width: 1.1rem;
          font-size: 14px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $main_theme_color_333;
          padding: 8px 0.2rem;
          border: 0.01rem solid #c8c8c8;
          border-radius: 0.06rem;
          cursor: pointer;
          box-sizing: border-box;
          margin-right: 20px;
        }

        .s_c__sp_active {
          background-color: $main_theme_color !important;
          color: $main_theme_color_333 !important;
          border: 0.01rem solid $main_theme_color !important;
        }
      }

      .up_ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0 30px;

        .up_li {
          text-align: center;
          width: 3.44rem;
          margin-bottom: 30px;
          margin-right: 95px;

          &:nth-child(3n + 3) {
            margin-right: 0px;
          }

          .up_load {
            background-color: #f0f0f0;
            width: 3.44rem;
            height: 2.4rem;
            border-radius: 0.06rem;
            position: relative;
            z-index: 99;

            .up_load_img {
              width: 100%;
              position: absolute;
              bottom: 0;
              right: 0;
              top: 0;
              z-index: 1;
              text-align: center;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;

              &:hover .pic_look {
                opacity: 1;
              }

              img {
                max-width: 100%;
                max-height: 100%;
              }

              .pic_look {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 0.5rem;
                background-color: rgba(0, 0, 0, 0.6);
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.16rem;
                opacity: 0;
                transition: opacity 0.6s;
                z-index: 999999;

                img {
                  width: 0.24rem;
                  height: 0.16rem;
                  margin-right: 0.1rem;
                }
              }
            }

            .remove_pic {
              position: absolute;
              right: 0.1rem;
              top: 0.1rem;
              width: 0.3rem;
              height: 0.3rem;
              z-index: 9;
              cursor: pointer;
              border-radius: 100%;
              background-size: 100% 100%;
              background-image: url("/static/images/createCases/delet_img.png");
            }

            .up_load_def {
              position: absolute;
              left: 0;
              top: 0.5rem;
              right: 0;
              height: 1rem;
              z-index: 9;
              background-size: 100% 100%;
            }

            .up_load_def1 {
              background-image: url("/static/images/createCases/side.png");
            }

            .up_load_def2 {
              background-image: url("/static/images/createCases/front.png");
            }

            .up_load_def3 {
              background-image: url("/static/images/createCases/smile.png");
            }

            .up_load_def4 {
              background-image: url("/static/images/createCases/top.png");
            }

            .up_load_def5 {
              background-image: url("/static/images/createCases/bottom.png");
            }

            .up_load_def6 {
              background-image: url("/static/images/createCases/right.png");
            }

            .up_load_def7 {
              background-image: url("/static/images/createCases/main.png");
            }

            .up_load_def8 {
              background-image: url("/static/images/createCases/left.png");
            }

            .up_load_def9 {
              background-image: url("/static/images/createCases/select.png");
              background-repeat: no-repeat;
            }

            .up_load_def10 {
              background-image: url("/static/images/createCases/qyfgx.png");
              background-repeat: no-repeat;
            }

            .up_load_def11 {
              width: 92px;
              height: 52px;
              left: 50%;
              top: 82px;
              transform: translateX(-50%);
              background-image: url("/static/images/createCases/yhx.png");
              background-repeat: no-repeat;
            }

            .up_load_def12 {
              width: 74px;
              height: 67px;
              left: 50%;
              top: 73px;
              transform: translateX(-50%);
              background-image: url("/static/images/createCases/khx.png");
              background-repeat: no-repeat;
            }
          }
        }

        .up_txt {
          font-size: 16px;
          color: $main_theme_color_333;
          margin-top: 0.1rem;

          span {
            color: $main_theme_color;
          }
        }
      }
    }
  }

  .carousel_option {
    margin-top: 0.25rem;
    padding-bottom: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .o_m {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      margin-right: 0.6rem;

      &:last-child {
        margin-right: 0;
      }

      img {
        width: 0.24rem;
        height: 0.24rem;
        margin-bottom: 0.05rem;
      }

      span {
        color: #999999;
        font-size: 0.16rem;
      }
    }
  }
}

.up_box {
  width: 3.44rem;
  height: 2.4rem;
  position: relative;
  background: #f0f0f0;

  .up_box_tip {
    width: 100%;
    position: absolute;
    bottom: 0.26rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: $main_theme_color_333;

    .up___tip_icon {
      display: inline-block;
      width: 0.25rem;
      height: 0.25rem;
      background-size: 100% 100%;
      background-image: url("/static/images/createCases/add_upload_img.png");
      border-radius: 100%;
      margin-right: 0.1rem;
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.cancel_btn,
.sure_btn {
  width: 96px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #333333;
}

.cancel_btn {
  border: 1px solid #bbbbbb;
  margin-right: 32px;
}

.sure_btn {
  background: $main_theme_color;
}

.download_btn {
  color: $main_theme_color;
  font-size: 15px;
}

.disabled_btn {
  color: #cbcbcb;
}

.disabled_btn_two {
  opacity: 0.6;
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
